import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// 用vuex.store对象用来记录token
const key = 'token'
const store = new Vuex.Store({
  state () {
    return {
      token: sessionStorage.getItem('token') || '',
      userName: ''
    }
  },
  getters: {
    getToken (state) {
      if (!state.token) {
        state.token = sessionStorage.getItem('token')
      }
      return state.token
    }
  },
  mutations: {
    // 修改token，并将token存入localStoreage
    setToken (state, token) {
      state.token = token
      sessionStorage.setItem(key, token)
      // console.log('store/sessionStorage保存token成功')
    },
    delToken (state) {
      state.token = ''
      sessionStorage.removeItem(key)
    }
  },
  actions: {
    // removeToken: (context) => {
    //   context.commit('setToken')
    // }
  }
})

export default store
