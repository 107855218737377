<template>
  <div id="app" class="app" @click="isTimeOut">
    <headerTop v-show="!$route.meta.hideHeader"></headerTop>
    <div style="min-height: 900px;">
      <router-view v-if="isRouterAlive"></router-view>

    </div>
    <footerBtm v-show="!$route.meta.hideFooder"></footerBtm>
    <el-button class="totop" circle v-show="gotop" @click="totop">
      <i class="el-icon-arrow-up" style="padding: 10px;"></i>
    </el-button>
    <!-- 客服联系 -->
    <div class="fixed" style="bottom: 360px;z-index: 999;">
      <div class="online" @click="toQD()">
        <img src="@/assets/img/cs.png" alt="">
        <span>定制服务</span>
      </div>
    </div>
    <el-popover class="fixed" placement="left-start" title="" width="250" trigger="hover">
      <div>
        <div class="link bot">
          <img src="@/assets/img/91.png" alt="QQ号码" width="50px">
          <div style="margin-left: 20px;">
            <p>QQ：26303605</p>
            <p>在线客服，实时响应</p>
          </div>
        </div>
        <div class="link bot">
          <img src="@/assets/img/36.png" alt="联系方式" width="50px">
          <div style="margin-left: 20px;">
            <p>联系方式：</p>
            <p>19907977089</p>
          </div>
        </div>
        <div class="link">
          <div style="text-align: center;">
            <img src="@/assets/img/wx.jpg" alt="企业微信" width="100px">
            <p>企业微信</p>
          </div>
          <div style="margin-left: 20px;text-align: center;">
            <img src="@/assets/img/qq.jpg" alt="企业QQ" width="100px">
            <p>企业QQ</p>
          </div>
        </div>
      </div>
      <div slot="reference">
        <div class="online" id="kuainiaoip">
          <img src="@/assets/img/cs.png" alt="">
          <span>在线咨询</span>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import headerTop from '@/components/headerTop.vue'
import footerBtm from '@/components/footerBtm.vue'

export default {
  name: 'app',
  components: {
    headerTop,
    footerBtm
  },
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      msg: '',
      gotop: false,
      isRouterAlive: true,
      // 最后一次点击时间
      lastTime: null,
      // 当前点击的时间
      currentTime: null,
      // 超时时间：30分钟
      timeOut: 1 * 30 * 60 * 1000
      // timeOut: 1 * 60 * 1000
    }
  },
  created () {
    this.lastTime = new Date().getTime()
    // let url = window.location.search
    // console.log('============', url)
    // console.log(url.indexOf('bd_vid')
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true)
    // 域名
    // console.info('hostname:', window.location.hostname)
    // id:"qd3009066708b8c1af79f42d37087494f8cc12bbf358"
    // src:"https://wp.qiye.qq.com/qidian/3009066708/b8c1af79f42d37087494f8cc12bbf358"
    // charset="utf-8” async defer
    // 在线客服
    const oScript = document.createElement('script')
    oScript.src = 'https://wp.qiye.qq.com/qidian/3009066708/a09da3515f2483ec6cad508caeeeb9e7'
    // oScript.src = 'https://wpa1.qq.com/WQx091qv?_type=wpa&qidian=true'
    oScript.id = 'qd3009066708a09da3515f2483ec6cad508caeeeb9e7'
    oScript.charset = 'utf-8'
    oScript.async = true
    oScript.defer = true
    document.body.appendChild(oScript)
  },
  methods: {
    // 拉取QQ
    toQD () {
      window.open('https://wpa1.qq.com/WQx091qv?_type=wpa&qidian=true', '_blank')
    },
    // 超时自动关闭
    isTimeOut () {
      // 记录这次的点击时间
      this.currentTime = new Date().getTime()
      console.log(this.currentTime - this.lastTime)
      console.log(this.timeOut)
      // 判断上次最后一次点击时间和这次点击时间间隔是否大于30分钟
      if (this.currentTime - this.lastTime > this.timeOut) {
        // 若为登录状态
        if (sessionStorage.getItem('token')) {
          // this.$message.error('登录超时，请重新登录')
          sessionStorage.removeItem('token')
          setTimeout(() => {
            this.$router.push('/')
            // 先返回首页,在给提示,可以根据具体需求调整
            this.$alert('检测到您长时间未操作页面,请重新登录!', '温馨提示', {
              confirmButtonText: '确定',
              callback: (action) => {
                location.reload()
              }
            })
          }, 2000)
        } else {
          this.lastTime = new Date().getTime()
        }
      } else {
        // 若30分钟内点击，则覆盖之前存储的最后一次点击时间
        this.lastTime = new Date().getTime()
      }
    },
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    handleScroll () {
      const scrolltop = document.documentElement.scrollTop || document.body.scrollTop
      // console.log('scrolltop', scrolltop)
      if (scrolltop > 100) {
        this.gotop = true
      } else {
        this.gotop = false
      }
    },
    totop () {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      // console.log('top', top)
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    },
    getUserInfo () {
      this.$apiFun.getUserInfo().then(res => {
        // console.log(res)
        if (res.code == 1) {
          this.veri = res.data.certificationType
          sessionStorage.setItem('indet', res.data.certificationType)
        }
      })
    }
  },
  watch: {
    // 页面跳转后返回顶部
    $route (to) {
      document.getElementsByClassName('app')[0].scrollTop = 0 // 设置滚动条位置
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}

.totop {
  position: fixed;
  bottom: 290px;
  right: 50px;
  z-index: 3;
  /* box-shadow: 2px 2px 10px #efefef; */
  box-shadow: 0 2px 6px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}

.fixed {
  position: fixed;
  bottom: 445px;
  right: 50px;
  z-index: 3;
}

.online {
  color: #409eff;
  /* border-radius: 10px; */
  background-color: #fff;
  /* width: 40px; */
  /* height: 130px; */
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #efefef;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  /* box-shadow: 2px 2px 10px #efefef; */
  cursor: pointer;
}

.online span {
  font-size: 12px;
}

.online:hover {
  background-color: #efefef;
}

/* .online span:after{
  content: '点击咨询';
  cursor: pointer;
  position: absolute;
  opacity: 0;
  top: -20px;
  right: 0;
  transition: 1s;
  padding: 9px 20px;
  display: flex;
  flex-direction: column;
  background-color: #409eff;
  border: 2px solid #409eff;
  color: #fff;
  border-radius: 10px;
} */
/* .online:hover span {
  padding-right: 3.55em;
} */
.online:hover span:after {
  opacity: 4;
  top: 0;
}

.bot {
  border-bottom: 1px solid #efefef;
}

.link {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
}

::v-deep .el-button {
  border: none;
}</style>
