import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import VueAnimateNumber from 'vue-animate-number'
import 'animate.css'
import 'default-passive-events'
import store from './store'
import apiFun from './request/api'
import preventReClick from './assets/js/preventRepeatClick'
import wow from 'wowjs'

Vue.use(preventReClick)

Vue.prototype.$apiFun = apiFun // 请求接口api

Vue.use(VueAnimateNumber)

Vue.prototype.$bus = new Vue()
Vue.prototype.$wow = wow

Vue.config.productionTip = false

Vue.prototype.setSessionItem = function (key, newVal) {
  // 创建 StorageEvent 事件
  const newStorageEvent = document.createEvent('StorageEvent')
  const storage = {
    setItem: function (k, val) {
      sessionStorage.setItem(k, val)
      // 初始化 StorageEvent 事件
      newStorageEvent.initStorageEvent(
        'setItem', // 事件别名
        false,
        false,
        k,
        null,
        val,
        null,
        null
      )
      // 派发事件
      window.dispatchEvent(newStorageEvent)
    }
  }
  return storage.setItem(key, newVal)
}

// 设置路由拦截
router.beforeEach((to, from, next) => {
  // 判断是否需要登录验证
  const token = window.sessionStorage.getItem('token')
  // /如果页面需要拦截
  if (to.matched.some(record => record.meta.requireAuth)) {
    // 存在用户信息则不拦截
    if (token) {
      next()
    } else {
      // 没有登录则提示并重定向
      Vue.prototype.$message.error('请先进行登录操作！')
      next('/')
    }
  // 不需要拦截
  } else {
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
