<template>
  <div>
    <div class="position-relative" style="background-color: rgba(47, 52, 69, 1);">
      <!-- <img class="footer-shape" src="@/assets/img/15.png" alt="shape"> -->
      <div class="container">
        <div class="row mb-n7">
          <div class="col-lg-4 col-sm-6">
            <div class="footer-widget">
              <div class="footer-logo mb-9" href="">
                <img :src="kfimg" class="s-img" alt="footer-logo">
                <p class="col-lg-9" style="color: rgba(170, 175, 191, 1);">企业级HTTP代理IP，遍布全国200+城市服务器，从容应对海量需求</p>
              </div>

            </div>
          </div>
          <div class="col-lg-3 col-sm-6 mb-7">
            <div class="footer-widget">
              <h4 class="title color-white">产品导航</h4>
              <ul class="footer-menu">
                <li class="s-li"><i class="el-icon-arrow-right color-whiteS"></i><a class="footer-link"
                    href="/short-term">短效优质代理</a>
                </li>
                <li class="s-li"><i class="el-icon-arrow-right color-whiteS"></i><a class="footer-link"
                    href="/long-term">长效代理</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 mb-7">
            <div class="footer-widget">
              <h4 class="title color-white">相关咨询</h4>
              <ul class="footer-menu">
                <li class="s-li"><i class="el-icon-arrow-right color-whiteS"></i><a class="footer-link"
                    href="/help">行业咨询</a></li>
                <li class="s-li"><i class="el-icon-arrow-right color-whiteS"></i><a class="footer-link"
                    href="/help">使用教程</a></li>
                <li class="s-li"><i class="el-icon-arrow-right color-whiteS"></i><a class="footer-link"
                    href="/help">常见问题</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 mb-7">
            <div class="footer-widget">
              <h4 class="title color-white">联系我们</h4>
              <ul class="footer-menu">
                <!-- <li><a class="footer-link" href="#">城市：{{city}}</a></li> -->
                <li class="s-li"><i class="el-icon-arrow-right color-whiteS"></i><a class="footer-link"
                    @click="copy(address)">详细地址：{{
                      address }}</a></li>
                <li class="s-li"><i class="el-icon-arrow-right color-whiteS"></i><a class="footer-link"
                    @click="copy(mobile)">电话：{{
                      mobile | line }}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copy-right-section" style="padding: .4375rem 0 .6875rem 0;">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div style="width:700px;margin:0 auto; color: #a8abaa;">
              Copyright &copy; 2023 江西堆栈网络科技有限公司厦门分公司版权所有
              <a target="_blank" href="https://beian.miit.gov.cn"
                style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                <p style="height:20px;line-height:20px;margin: 0px 0px 0px 5px;color: #a8abaa;">赣ICP备19009812号-23</p>
              </a>
              <div style="width: 300px;margin: 0 auto;">
                <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=36070202000771" target="_blank">
                  <img src='@/assets/img/security.png' alt="" style="width: 20px;float: left;">
                  <p style="height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;float: left;">赣公网安备
                    36070202000771号</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      baserurl: 'https://api.kuainiaoip.com',
      logo: '',
      kfimg: '',
      gzhimg: '',
      city: '',
      address: '',
      mobile: ''
    }
  },
  created () {
    this.getSiteInfo()
  },
  filters: {
    line (val) {
      if (val.length !== 11) return val
      const arr = val.split('')
      arr.splice(3, 0, '-')
      arr.splice(8, 0, '-')
      return arr.join('')
    }
  },
  methods: {
    getSiteInfo () {
      const data = {
        domainname: window.location.hostname
      }
      this.$apiFun.getWebsiteConfig(data).then(res => {
        console.log(res)
        if (res.code == 1) {
          this.logo = this.baserurl + res.data.logo
          this.setSessionItem('logo', this.logo)
          if (res.data.banner) {
            this.banner = this.baserurl + res.data.banner
            this.setSessionItem('banner', this.banner)
          } else {
            this.setSessionItem('banner', '')
          }
          // sessionStorage.setItem('', )
          sessionStorage.setItem('pid', res.data.pid)
          sessionStorage.setItem('name', res.data.name)
          const site = res.data.domainname.split('//')[1].split('ip.com')[0]
          sessionStorage.setItem('site', site)
          this.kfimg = this.baserurl + res.data.workweixin
          this.gzhimg = this.baserurl + res.data.mpeixin
          this.city = res.data.city
          this.address = res.data.city.replace('/', '').replace('/', '') + res.data.address
          this.mobile = res.data.mobile
        } else {
          this.$message.info(res.msg)
        }
      })
    },
    copy (context) {
      console.log(context)
      navigator.clipboard.writeText(context).then(() => {
        this.$message.success('复制成功')
      }).catch(err => {
        this.$message.error('复制失败')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/plugins.min.css';
  @import '@/assets/css/style.min.css';
  @import '@/assets/css/vendor.min.css';
.position-relative {
  height: 18.75rem;

  .container {
    padding-top: 2.06rem;
    .col-lg-4{
      width: 49%;
    }
    .col-lg-3 {
      padding: 0;
      width:17%;

      padding-top: .8125rem;

      .color-white {
        color: #fff;
        font-size: 1.25rem;
        margin-bottom: .75rem;
        padding-left: .3125rem;
      }

      .footer-menu {
        .s-li {
          display: flex;
          align-content: center;
          margin-bottom: 1.0625rem;

          .footer-link {
            color: rgba(170, 175, 191, 1);
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.4481rem;
          }

          .color-whiteS {
            color: rgba(170, 175, 191, 1);
            display: flex;
            padding-top: .1875rem;
            margin-right: .375rem;
            align-content: flex-start;
            font-size: 1.125rem;
            font-weight: bold;
          }
        }
      }
    }

    .s-img {
      width: 11.5rem;
      height: 4.94rem;
      margin-bottom: 1.31rem;
    }

    .col-lg-9 {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 1.4375rem;
      color: rgba(170, 175, 191, 1);
      width: 17.5rem;
      text-align: left;
      vertical-align: top;
    }
  }
}
</style>
<style scoped></style>
